import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  BrowserRouter,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  createHashRouter,
} from "react-router-dom";
import Loader from "./Components/Loader/loader";

// import Home from "./Pages/Home/home";
// import About from "./Pages/About/about";
// import Contact from "./Pages/Contact/contacts";
// import Analysis from "./Pages/Analysis/analysis";
// import Courses from "./Pages/Courses/courses";
// import Resources from "./Pages/Resources/resources";
// import Indicator from "./Pages/Indicator/indicator";
// import AnalysisDetails from "./Pages/Analysis/analysisDetails";

const Home = lazy(() => import("./Pages/Home/home"));
const About = lazy(() => import("./Pages/About/about"));
const Contact = lazy(() => import("./Pages/Contact/contacts"));
const Analysis = lazy(() => import("./Pages/Analysis/analysis"));
const Courses = lazy(() => import("./Pages/Courses/courses"));
const Resources = lazy(() => import("./Pages/Resources/resources"));
const Indicator = lazy(() => import("./Pages/Indicator/indicator"));
const IndicatorDetails = lazy(
  () => import("./Pages/Indicator/indicatorDetails")
);
const Subscribe = lazy(() => import("./Pages/Indicator/subscribe"));
const AnalysisDetails = lazy(() => import("./Pages/Analysis/analysisDetails"));
const AnalysisDetailsAdmin = lazy(
  () => import("./PagesAdmin/AnalysisAdmin/analysisDetailsAdmin")
);
const AnalysisEdit = lazy(
  () => import("./PagesAdmin/AnalysisAdmin/analysisEdit")
);
const Login = lazy(() => import("./PagesAdmin//Login/login"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createHashRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <Suspense fallback={<Loader />}>
          <App />
        </Suspense>
      }
    >
      <Route index element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="contact" element={<Contact />} />
      <Route path="courses" element={<Courses />} />
      <Route path="resources" element={<Resources />} />
      <Route path="indicator" element={<Indicator />}>
        <Route path="indicatorDetails" element={<IndicatorDetails />} />
        <Route path="subscribe" element={<Subscribe />} />
      </Route>
      <Route path="analysis" element={<Analysis />}>
        <Route path="analysisDetails" element={<AnalysisDetails />} />
      </Route>
      <Route
        path="analysisDetailsAdmin"
        element={<AnalysisDetailsAdmin />}
      ></Route>
      <Route path="analysisEdit" element={<AnalysisEdit />}></Route>
      <Route path="login" element={<Login />}></Route>
    </Route>
  )
);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
