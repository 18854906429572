import { Logo } from "../../assests";
import "./loader.css";

function Loader(props: any) {
  const { isWait, text } = props;
  return (
    <div className="loader_conta">
      <img
        className="loader_img"
        src={Logo}
        width={"200px"}
        height={"200px"}
      ></img>
      {isWait ? (
        <p style={{ fontSize: "20px", fontWeight: "500", color: "white" }}>
          {text}
        </p>
      ) : null}
    </div>
  );
}

export default Loader;
